<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <CCardHeader tag="div">
          <CIcon name="cil-grid"/>
          Chi tiết tỉnh/thành phố
        </CCardHeader>
        <CCardBody style="min-height: 300px" tag="div">
          <CForm>
            <CRow>
              <CCol lg="6">
                <CInput label="Tên đơn vị" horizontal :value="detailItem.ten" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <CInput label="Vùng kinh tế" horizontal :value="detailItem.vungKinhTe_Ten" readonly/>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="6">
                <div role="group" class="form-group form-row">
                  <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                  <CCol sm="9" class="form-inline">
                    <CInputCheckbox label="Hoạt động" :checked="detailItem.trangThai" disabled/>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          </CForm>
          <CElementCover v-if="isFetchingSingle"/>
        </CCardBody>
        <CCardFooter tag="div" class="text-right">
          <CButton color="secondary" class="mr-2" @click="back"><CIcon name="cil-arrow-left" /> Quay lại</CButton>
          <CButton color="primary" class="mr-2" @click="editItem"><CIcon name="cil-pencil" /> Sửa</CButton>
          <CButton color="danger" @click="isDeleteConfirmationModalOpen = true"><CIcon name="cil-x" /> Xóa</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa tỉnh/thành phố"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="detailItem" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { DELETE_TINHTHANHPHO, GET_TINHTHANHPHO } from '@/store/modules/tinh-thanh-pho/actionTypes'

export default {
  name: 'TinhThanhPhoDetail',
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false
    }
  },
  computed: {
    ...mapGetters('tinhThanhPho', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('tinhThanhPho', {
      getTinhThanhPho: GET_TINHTHANHPHO,
      deleteItem: DELETE_TINHTHANHPHO
    }),
    back () {
      this.$router.push({ path: '/danh-muc/tinh-thanh-pho' })
    },
    editItem () {
      this.$router.push({ path: `/danh-muc/tinh-thanh-pho/${this.detailItem.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/danh-muc/tinh-thanh-pho' })
      }
    }
  },
  async created () {
    await this.getTinhThanhPho(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
